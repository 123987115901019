import { Box } from "@mui/material";
import styled from "styled-components";

interface CustomInputProps {
  type?: string;
  disabled?: boolean;
  placeholder?: string;
  id?: string;
  error?: string;
  name?: string;
  onChange?: any;
  value?: string;
  emailAddress?: string;
  className?: string;
  errorText?: any;
  style?: object;
  onBlur?: any;
  maxLength?: any;
  onInput?: any
}

const InputBox = styled.input`
  padding: 1rem 0rem 1rem 1rem;
  outline: none;
  border: 1.5px solid #ccc;
  display: block;
  background-color: #f5f4f4;
  font-family: var(--fontFamiljen);
  font-size: 16px;
  border-radius: 8px;
  width: 100%;
  ::placeholder {
    color: var(--InputTextGray);
    font-size: 16px;
    font-weight: 400;
    font-family: var(--fontFamiljen);
  }
  :focus {
    background-color: var(--themeOrangeLight);
    border: 1.5px solid var(--themeOrange);
  }
`;
export const Legend = styled.legend`
  display: block;
  width: 100%;
  margin: 0;
  font-family: var(--fontFamiljen);
  color: var(--legendColor);
  margin-bottom:0.2rem;
  font-size: 14px;
`;
const Errors = styled.p`
  color: var(--errorsColor);
  font-size: 12px;
  margin: 4px;

`;
const InputCustom = (props: CustomInputProps) => {
  const {
    disabled,
    type,
    placeholder,
    id,
    name,
    onChange,
    value,
    emailAddress,
    className,
    errorText,
    style,
    onBlur,
    maxLength,
    onInput
  } = props;
  return (
    <>
      <Legend>{emailAddress}</Legend>
      <InputBox
        type={type}
        placeholder={placeholder}
        id={id}
        name={name}
        onChange={onChange}
        value={value}
        className={className}
        style={style}
        onBlur={onBlur}
        maxLength={maxLength}
        disabled={disabled}
        onInput={onInput}
      />
      <Errors>{errorText}</Errors>
    </>
  );
};

export default InputCustom;



const ParentBox = styled.div`
padding:0;
display:flex;
border-radius: 8px;
border:1.5px solid #ccc;
background-color: #f5f4f4;
width:100%;
:focus-within {
background-color: var(--themeOrangeLight);
   border: 1.5px solid var(--themeOrange);
}
`

const InputBoxEndorment = styled.input`
  padding: 1rem 1rem 1rem 1rem;
  outline: none;
  display: block;
  font-family: var(--fontFamiljen);
  font-size: 16px;
  border:none !important;
  border-radius:0;

  width: 100%;
  background-color: transparent;
  ::placeholder {
    color: var(--InputTextGray);
    font-size: 16px;
    font-weight: 400;
    font-family: var(--fontFamiljen);
  }
`;

const Endorment = styled.div`
display:flex;
width:4rem;
justify-content:center;
align-items:center;
border-left:1.5px solid #ccc;
`

export const InputCustomSymbol = (props: any) => {
  const {
    disabled,
    type,
    placeholder,
    id,
    name,
    onChange,
    value,
    emailAddress,
    className,
    errorText,
    style,
    onBlur,
    maxLength,
    symbol,
    onInput
  } = props;
  return (
    <>
      <Legend>{emailAddress}</Legend>
      <ParentBox className={`${className}`}>
        <InputBoxEndorment
          type={type}
          placeholder={placeholder}
          id={id}
          name={name}
          onChange={onChange}
          value={value}
          className={className}
          style={style}
          onBlur={onBlur}
          maxLength={maxLength}
          disabled={disabled}
          onInput={onInput}
        />
        <Endorment>{symbol}</Endorment>
      </ParentBox>
      <Errors>{errorText}</Errors>
    </>
  );
};